.root {
  /* // footer={{ height: { base: 170, sm: 90 } }} */
  /* height: 90px; */
  /* @media (max-width: $mantine-breakpoint-sm) {
    height: 170px;
  } */
  /* margin-top: 120;
  border-top: 1px solid; */
  /* border-top-color: light-dark(
    var(--mantine-color-black),
    var(--mantine-color-white)
  ); */
  background: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-7)
  );
  padding-top: 20px;
  border-top-color: var(--mantine-color-dimmed);
  & a {
    color: var(--mantine-color-dimmed);
  }
}

.inner {
  /* display: flex; */
  /* justifycontent: space-between; */
  /* align-items: center;
  padding-top: var(--mantine-space-xl);
  padding-bottom: var(--mantine-space-xl); */
  /*
    [theme.fn.smallerThan(xs)]: {
      flexDirection:column,
    }, */
}
.group {
  justify-content: space-between;
  @media (max-width: $mantine-breakpoint-xs) {
    justify-content: center;
  }
}
.links {
  /* [theme.fn.smallerThan(xs)]: {
      marginTop: theme.spacing.md,
    }, */
}
