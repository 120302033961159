.appShell {
  background: light-dark(
    var(--mantine-color-gray-1),
    var(--mantine-color-dark-8)
  );
  /* background: linear-gradient(
    0deg,
    darken(var(--mantine-color-grape-filled-hover), 0.8),
    darken(var(--mantine-color-cyan-filled-hover), 0.8)
  ); */
  /* background: light-dark(
    var(--mantine-color-gray-1),
    var(--mantine-color-dark-9)
  ); */
  /* background: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-black)
  ); */
  /* &:before {
    display: none;
    opacity: light-dark(0.2, 0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    content: "";
    background: radial-gradient(
        circle at 50% 50%,
        darken(var(--mantine-color-cyan-9), light-dark(0, 0.8)) 20%,
        transparent 80%
      ),
      conic-gradient(
        from 45deg at 50% 50%,
        darken(var(--mantine-color-violet-9), light-dark(0, 0.8)) 0%,
        darken(var(--mantine-color-cyan-9), light-dark(0, 0.8)) 25%,
        darken(var(--mantine-color-violet-9), light-dark(0, 0.8)) 50%,
        darken(var(--mantine-color-cyan-9), light-dark(0, 0.8)) 75%,
        darken(var(--mantine-color-violet-9), light-dark(0, 0.8)) 100%
      );
  } */
}
