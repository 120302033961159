.text {
  background-image: light-dark(
    url("/assets/images/widelogo-dark.png"),
    url("/assets/images/widelogo.png")
  );
  background-repeat: no-repeat;
  padding: 0;
  height: 32px;
  width: 167px;
  text-overflow: "hidden";
  text-indent: -9999em;
  padding-top: 8px;
  background-size: 155px;
  background-position: 10px 8px;
}
