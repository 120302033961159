.linkItemButton {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs);
  borderradius: var(--mantine-radius-sm);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  @media (max-width: 768px) {
    font-size: 5px;
  }
}
